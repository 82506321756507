<template>
  <template
    v-if="
      userPermissions[props.module]?.includes(props.action) ||
      roles?.includes(userRole)
    "
  >
    <slot />
  </template>
</template>
<script setup>
import { getUserPermissions, getUserRole } from "@/core/config/MainMenuConfig";
import { onMounted, ref } from "vue";

const props = defineProps({
  module: {
    type: String,
    default: "",
  },
  action: {
    type: String,
    default: "",
  },
  roles: {
    type: Array,
    default: () => ["Superadmin"],
  },
});

const userPermissions = getUserPermissions();
const userRole = getUserRole();
const roles = ref(props.roles);

onMounted(() => {
  roles.value = ["Superadmin", ...props.roles];
});
</script>

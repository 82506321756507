<template>
  <permission-wrapper action="extract" :module="permissionModuleName">
    <v-btn
      @click="handleExport"
      class="mr-1 text-grey btn-bg"
      color="light"
      v-if="hasExport"
    >
      <v-icon>mdi-export-variant</v-icon>
      Exporter
    </v-btn>
  </permission-wrapper>
  <permission-wrapper action="import" :module="permissionModuleName">
    <v-btn
      class="mr-1 text-grey btn-bg"
      color="light"
      @click="openDialog = true"
      :disabled="importing"
      :loading="importing"
      v-if="hasImport"
    >
      <v-icon color="grey">mdi-file-import</v-icon>
      Importer
    </v-btn>
  </permission-wrapper>

  <v-dialog max-width="600px" v-model="openDialog">
    <form @submit.prevent="handleImport">
      <v-card>
        <v-card-title
          class="w-full d-flex align-items-center justify-space-between"
          >Import Excel File
          <v-btn
            class="float-right"
            color="info"
            @click="downloadTemplate"
            :disabled="downloadingTemplate"
            :loading="downloadingTemplate"
          >
            Télécharger le model
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-file-input
            accept=".xls, .xlsx"
            label="Choisir un fichier Excel"
            name="file"
            v-model="file"
          ></v-file-input>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="openDialog = false"> Cancel </v-btn>
          <v-btn
            type="submit"
            color="success"
            :disabled="!file || importing"
            :loading="importing"
            >Import</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import { jsonToExcel } from "@/utils/excel";
import { getUserPermissions } from "@/core/config/MainMenuConfig";

const props = defineProps({
  moduleName: {
    type: String,
    default: "",
  },
  permissionModuleName: {
    type: String,
    default: "",
  },
  hasImport: {
    type: Boolean,
    default: true,
  },
  hasExport: {
    type: Boolean,
    default: true,
  },
  exportClientSide: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits(["imported"]);

const userPermissions = getUserPermissions();

const openDialog = ref(false);
const file = ref(null);
const importing = ref(false);
const exporting = ref(false);
const downloadingTemplate = ref(false);

const handleImport = async () => {
  try {
    importing.value = true;
    const formData = new FormData();
    formData.append("file", file.value[0] ?? file.value);
    const res = await axios.post(`/api/import/${props.moduleName}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Fichier importé avec succès",
      });

      emits("imported");
    } else {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Erreur lors de l'importation du fichier",
      });
    }
  } catch (e) {
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Erreur lors de l'importation du fichier",
    });
  } finally {
    importing.value = false;
    openDialog.value = false;
  }
};

const handleExport = async () => {
  exporting.value = true;

  if (props.exportClientSide) {
    exportClientSide();
    exporting.value = false;
    return;
  }

  const url = `/api/export/${props.moduleName}`;
  const filename = `${props.moduleName}-${
    new Date().toISOString().split("T")[0]
  }-export.xlsx`;
  await downloadFile(url, filename);
  exporting.value = false;
};

const downloadTemplate = async () => {
  const url = `/api/download_model/${props.moduleName}`;
  const filename = `${props.moduleName}-${
    new Date().toISOString().split("T")[0]
  }-template.xlsx`;
  downloadTemplate.value = true;
  await downloadFile(url, filename);
  downloadTemplate.value = false;
};

const downloadFile = async (url, filename) => {
  try {
    const res = await axios.get(url, {
      responseType: "blob",
    });
    if (res.status !== 200) {
      throw new Error("Erreur lors du téléchargement du fichier");
    }
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    Swal.fire({
      icon: "error",
      title: "Erreur",
      text: "Erreur lors du téléchargement du fichier",
    });
  }
};

const exportClientSide = async () => {
  const filename = `${props.moduleName}-${
    new Date().toISOString().split("T")[0]
  }-export`;

  if (props.columns.length === 0) {
    jsonToExcel(props.data, filename);
    return;
  }

  const selectedData = [];
  props.data.forEach((item) => {
    const obj = {};
    props.columns.forEach((col) => {
      const keys = col.key.split(".");
      let value = item;
      keys.forEach((key) => {
        value = value[key] ?? "";
      });

      if (col.formatter) {
        value = col.formatter(value);
      } else if (Array.isArray(value)) {
        value = value.join(", ");
      }

      obj[col.title] = value;
    });
    selectedData.push(obj);
  });

  jsonToExcel(selectedData, filename);
};
</script>

<style>
.swal2-container {
  z-index: 99999999 !important;
}
.btn-bg {
  background-color: #f5f8fa !important;
}
</style>
